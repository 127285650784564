<!-- eslint-disable vue/valid-v-model -->
<!-- eslint-disable vue/no-side-effects-in-computed-properties -->
<template>
  <div class="pa-2">
    <block v-if="loading"></block>

    <v-card flat class="pa-4 bar">
      <div class="d-flex justify-space-between align-center">
        <div>
          <v-btn
            v-if="editing && $store.getters['rolesUser/getCustomRole'](199)"
            small
            color="error"
            @click.prevent="confirmDeleteAccountingEntry"
            style="margin-right: 0.3rem"
          >
            <v-icon> mdi-delete </v-icon>
          </v-btn>
        </div>
        <div>
          <v-btn v-if="editing && !allowEditing && $store.getters['rolesUser/getCustomRole'](198)"
            legaln
            small
            color="btn-add"
            style="margin-right: 0.3rem"
            @click.prevent="enableEditing"
          >
            <v-icon color="primary">mdi-eye</v-icon>
          </v-btn>
          <v-btn v-if="$store.getters['rolesUser/getCustomRole'](196) && (!win.meta?.accountingEntryToModify && !editing)"
            legaln
            small
            color="btn-add"
            style="margin-right: 0.3rem"
            @click.prevent="
              showHistory = true;
              keyHistory++;
            "
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
          <v-btn v-if="!win.meta?.accountingEntryToModify"
            small
            color="btnclean"
            style="margin-right: 0.3rem"
            @click.prevent="confirmCleanAccountingEntryForm"
          >
            <v-icon>mdi-broom</v-icon>
          </v-btn>
          <v-btn small class="btnsave" @click.prevent="saveAccountingEntry" 
            v-if="$store.getters['rolesUser/getCustomRole'](197)">
            <v-icon> mdi-content-save </v-icon>
          </v-btn>
        </div>
      </div>
    </v-card>
    <div class="d-flex flex-wrap align-self-center justify-left mb-3 mt-5">
      <h1><b>Partida contable</b></h1>
    </div>
    <div v-if="form.ID_Accounting_item" class="d-flex flex-wrap align-self-center justify-left mb-3 mt-5">
      <h4>Correlativo: <b>{{ form.ID_Accounting_item }}</b></h4>
    </div>
    <v-form ref="accountingEntryForm" v-model="valid">
      <v-row class="mt-2">
        <v-col cols="12" md="3">
          <date-picker
            label="Fecha"
            @dateSelected="changueD"
            v-model="date"
            :value="date"
            :disbaled="cannotEdit"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            label="Concepto"
            v-model="form.concept"
            outlined
            :rules="[(v) => !!v || 'Este campo es requerido']"
            required
            clearable
            dense
            :disabled="cannotEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-autocomplete
            label="Tipo"
            v-model="form.type"
            attach
            outlined
            :rules="[(v) => !!v || 'Este campo es requerido']"
            required
            id="jumper"
            dense
            clearable
            item-value="id_accounting_entry_type"
            item-text="accounting_entry_name"
            :items="TypesItems"
            :disabled="cannotEdit"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-form>
    <div>
      <!-- Table 4 start - Services -->
      
       
          <v-col col="6">
            <v-btn :disabled="cannotEdit" class="btn-add" @click.prevent.stop="addAccount" >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </v-col>
        
          <div style="overflow-x: scroll;">
            <table id="detailstable">
              <tr>
                <th v-for="({ readableName }, index) in tableHeaders" :key="index">
                  {{readableName}}
                </th>
              </tr>
              <tr v-for="(element, index) in AcountList" :key="index">
                <td v-for="({ dbName, editable, events, link, style }, headerIndex) in tableHeaders" :key="headerIndex" :class="link ? 'd-flex justify-center' : ''" >
                  <input v-if="editable" class="tableinput" v-model="AcountList[ index ][ dbName ]" v-on="{ ...throughEvents( events, index ) }" :readonly="true" :disabled="cannotEdit" :style="style ? style : {}" />
                  <a v-if="!cannotEdit && !editable && link === 1" href="#" class="mx-1" @click="chooseAccount( index )" v-on="{ ...throughEvents( events, index ) }">
                    <v-icon>mdi-magnify</v-icon>
                  </a>
                  <a v-if="!cannotEdit && !editable && link === 2" href="#" small color="btndelete" @click="confirmDeleteAccountingEntryDetail( index )" v-on="{ ...throughEvents( events, index ) }">
                    <v-icon color="error"> mdi-delete </v-icon>
                  </a>
                  <div v-if="!editable && !link" :style="{ ...style ? style : {}, display: 'inline-block'}" >
                    {{ AcountList[ index ][ dbName ] }}
                  </div>
                </td>
              </tr>
            </table>
          </div>

    </div>
    <v-row>
      <v-col cols="12" md="4">
        <v-card-title> Total Debe: {{ Currency }}{{ Totals.TotalDebe }} </v-card-title>
      </v-col>
      <v-col cols="12" md="4">
        <v-card-title> Total Haber: {{ Currency }}{{ Totals.TotalHaber }} </v-card-title>
      </v-col>
      <v-col cols="12" md="4">
        <v-card-title v-if="Totals.Total == 0 || Totals.Total == '0.00'">
          Diferencia: {{ Currency }}{{ Totals.Total }}
        </v-card-title>
        <v-card-title v-else style="color: red">
          Diferencia: {{ Currency }}{{ Totals.Total }}
        </v-card-title>
      </v-col>
    </v-row>

    <!-- Show  Modal -->
    <v-dialog v-model="showModel" width="90%">
      <v-card>
        <AccountingItemForm :win="win" v-on:sendSelection="reaciveSelection" 
        v-on:closeDialog="showModel = false" :key="keyModel"
        />
      </v-card>
    </v-dialog>
    <!-- <v-dialog v-model="showModelToChoose" width="60%">
      <v-card>
        <AccountingItemForm :win="win" v-on:sendSelection="reaciveSelection" />
      </v-card>
    </v-dialog> -->
    <v-dialog v-model="showHistory" scrollable  :width="dialogWidth">
      <v-card style="max-height:inherit">
        <AcountItemHistory
          v-on:sendetails="setupvalues"
          :key="keyHistory"
          :win="win"
          :entryTypes="TypesItems"
        />
      </v-card>
    </v-dialog>

    <alerts
      v-if="alert.show"
      v-on:close_alert="closeAlert"
      v-on:accept_alert="acceptAlert"
      :properties="alert"
    ></alerts>
  </div>
</template>

<script>

import DatePicker from "@/components/DatePicker";
import AccountingItemForm from "./AccountingItemForm.vue";
import AcountItemHistory from "./AcountItemHistory.vue";
import Alerts from "@/components/Alerts";
import { mapState } from "vuex";
import { printToExcel } from "@/helpers/printToexcel";
import { formatDateV2 } from "../../../helpers/formatDate";
import Block from "@/components/Block";
import moment from 'moment';
// import Vue from 'vue'

export default {
  name: "AccountingItem",
  props: ["win"],
  components: {
    // DxDataGrid,
    // DxKeyboardNavigation,
    // DxColumn,
    // DxPager,
    DatePicker,
    // DxPaging,
    // DxScrolling,
    AccountingItemForm,
    // DxEditing,
    // DxPatternRule,
    Alerts,
    Block,
    AcountItemHistory,
    // DxTextBox
    /*  DxExport, */

  },

  data() {
    return {
      editing: false,
      preventEnterCounter: 0,
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      
      },
      tab: null,
      focusedRowKey: 0,
      panel: 0,
      tag: true,
      form: {},
      TypesItems: [
        { name: "Debe", value: "Debe" },
        { name: "Haber", value: "Haber" },
      ],
      detailTypes: [],
     
      keyField: "id",
      loading: false,
      AcountList: [],
      defaultAccountItem: {
        "Deb": (0).toFixed(2),
        "Hab": (0).toFixed(2),
        "isDefault": true,
      },
      allowEditing: false,
      targetIndex: null,
      AcountItem: null,
      showHistory: false,
      search: {
        filter: null,
      },
      date: null,
      showModel: false,
      header: [],
      keyHistory: 0,
      changue: false,
      Totals: {
        TotalDebe: 0,
        TotalHaber: 0,
        Total: 0,
      },
      keyModel: 0,
      valid: false,
      AcountListN: [],
      RemovedAccountList: [],
      selectedToDelete: null,
      selectedToAssign: null,
      Currency: JSON.parse(localStorage.getItem("branch")).Currency,
      editableFieldsList: [
        "account_code",
        "concept",
        "Deb",
        "Hab",
        "Comentario"
      ],
      deleteByEnter: false,
      openCatalogByEnter: false,
      tableHeaders: [
        {
          readableName: '',
          dbName: '',
          editable: false,
          link: 1,
          events: {
            keyup: (index, e) => {
              if ( e.key === 'ArrowRight' ) {
                this.navigateThroughInputs( e.target, 1 );
              } else if ( e.key === 'ArrowLeft' ) {
                this.navigateThroughInputs( e.target, 0 );
              }
            },
          }
        },
        {
          readableName: 'Código',
          dbName: 'account_code',
          editable: true,
          events: {
            blur: (index, e) => {
              this.accountCodeTyped( index );
              this.disableInput( e.target );
            },
            keyup: (index, e) => {
              const currentElement = e.target;

              if(currentElement.hasAttribute( "readonly" )) {
                if ( e.key === 'ArrowRight' ) {
                  this.navigateThroughInputs( e.target, 1 );
                } else if ( e.key === 'ArrowLeft' ) {
                  this.navigateThroughInputs( e.target, 0 );
                } else if( e.key != 'Tab' ) {
                  this.enableInput( e.target );    
                }
              } else if( e.key === 'Enter' ) {
                this.accountCodeTyped(index);
                this.navigateThroughInputs( e.target, 1 );
              }
            },
            keypress: ( index, e ) => {
              this.enableInput( e.target );
            },
            click: ( index, e ) => {
              this.enableInput( e.target );
            }
          },
          style: {
            width: '100px !important'
          }
        },
        {
          readableName: 'Nombre',
          dbName: 'account_description',
          style: {
            width: '250px !important'
          }
        },
        {
          readableName: 'Concepto',
          dbName: 'concept',
          editable: true,
          events: {
            blur: (index, e) => {
              this.disableInput( e.target );
            },
            keyup: (index, e) => {
              const currentElement = e.target;

              if(currentElement.hasAttribute( "readonly" )) {
                if ( e.key === 'ArrowRight' ) {
                  this.navigateThroughInputs( e.target, 1 );
                } else if ( e.key === 'ArrowLeft' ) {
                  this.navigateThroughInputs( e.target, 0 );
                } else if( e.key != 'Tab' ) {
                  this.enableInput( e.target );    
                }
              } else if( e.key === 'Enter' ) {
                this.navigateThroughInputs( e.target, 1 );
              }
            },
            keypress: ( index, e ) => {
              this.enableInput( e.target );
            },
            click: ( index, e ) => {
              this.enableInput( e.target );
            }
          },
          style: {
            width: '250px !important'
          }
        },
        {
          readableName: 'Debe',
          dbName: 'Deb',
          editable: true,
          events: {
            blur: (index, e) => {
              this.formatValuesOnBlur( index, 'Deb' );
              this.disableInput( e.target );
            },
            keyup: (index, e) => {
              const currentElement = e.target;

              if(currentElement.hasAttribute( "readonly" )) {
                if ( e.key === 'ArrowRight' ) {
                  this.navigateThroughInputs( e.target, 1 );
                } else if ( e.key === 'ArrowLeft' ) {
                  this.navigateThroughInputs( e.target, 0 );
                } else if( e.key != 'Tab' ) {
                  this.enableInput( e.target );    
                }
              } else if( e.key === 'Enter' ) {
                this.navigateThroughInputs( e.target, 1 );
              }
            },
            keypress: ( index, e ) => {
              this.enableInput( e.target );
            },
            click: ( index, e ) => {
              this.enableInput( e.target );
            }
          },
          style: {
            width: '150px !important'
          }
        },
        {
          readableName: 'Haber',
          dbName: 'Hab',
          editable: true,
          events: {
            blur: (index, e) => {
              this.formatValuesOnBlur( index, 'Hab' );
              this.disableInput( e.target );
            },
            keyup: (index, e) => {
              const currentElement = e.target;

              if(currentElement.hasAttribute( "readonly" )) {
                if ( e.key === 'ArrowRight' ) {
                  this.navigateThroughInputs( e.target, 1 );
                } else if ( e.key === 'ArrowLeft' ) {
                  this.navigateThroughInputs( e.target, 0 );
                } else if( e.key != 'Tab' ) {
                  this.enableInput( e.target );    
                }
              } else if( e.key === 'Enter' ) {
                this.navigateThroughInputs( e.target, 1 );
              }
            },
            keypress: ( index, e ) => {
              this.enableInput( e.target );
            },
            click: ( index, e ) => {
              this.enableInput( e.target );
            }
          },
          style: {
            width: '150px !important'
          }
        },
        {
          readableName: '',
          dbName: '',
          editable: false,
          link: 2,
          events: {
            keyup: (index, e) => {

              if ( e.key === 'ArrowRight' ) {
                this.navigateThroughInputs( e.target, 1 );
              } else if ( e.key === 'ArrowLeft' ) {
                this.navigateThroughInputs( e.target, 0 );
              }
            },
          }
        },
      ]
    };
  },
  watch: {
    showModel() {
      if (!this.showModel) {
        // document.getElementById('jumper').addEventListener('keydown',this.checkTab);
        
        this.closeAccountsCatalog();
        this.closeDialog();
      }
    },
    AcountList: {
      handler: function () {
        this.Totals.TotalDebe = this.SumDeb();
        this.Totals.TotalHaber = this.SumHab();
        this.Totals.Total = this.TotalAcount();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("rolesUser", {
      rolesStatus: (state) => state.roles,
    }),
    cannotEdit(){
      return this.editing && !this.allowEditing;
    },
    dialogWidth() {

return this.$vuetify.breakpoint.smAndDown ? '85%' : '65%';
}
  },
  beforeDestroy() {
    document.removeEventListener("keydown");
  },

  async mounted() {
    // console.log('this.win.meta.accountingEntryToModify ', this.win.meta.accountingEntryToModify);
    

    
    try {
      this.loading = true;
      this.defaultAlert = { ...this.alert };
      this.AcountList.push( { ...this.defaultAccountItem } );
      // console.log(JSON.parse(localStorage.getItem("branch")).Currency);
      this.getAcounteringType();
      await this.getAllAcounts();
      
      this.getAccountingDetailTypes();
      this.$store.dispatch("rolesUser/getAllRoles");
      this.typebusiness = JSON.parse(localStorage.getItem("user")).businesstype || "";
  
      // console.log( JSON.parse( localStorage.getItem( "user" ) ) );
      this.filterServices = {};
      this.filterServices.business = JSON.parse(localStorage.getItem("user")).businessid;
      this.filterServices.branch = JSON.parse(localStorage.getItem("user")).branch;
      
      if(this.win.meta?.accountingEntryToModify) {
        await this.setupvalues(this.win.meta.accountingEntryToModify);
      }
      this.loading = false;
      // document.getElementById('jumper').addEventListener('keydown',this.checkTab);
      // window.addEventListener("keyup", this.validateEnterByCell);
     
    } catch (error) {
      console.log(error)
    }
    //this.getAllAcounts();
  },
  methods: {
    // validateHabDeb( field, index ){
    //   if( this.AcountList[ index ].Deb > 0.00 &&
    //   if( field === 'Deb' ) { 
    //   } else if( field === 'Hab' ) {

    //   }
    // },
    enableInput( currentElement ) {
      currentElement.classList.remove( "tableinput" );
      currentElement.removeAttribute( "readonly" );
      currentElement.classList.add( "tableinputeditable" );
    },
    disableInput( currentElement ) {
      currentElement.classList.remove( "tableinputeditable" );
      currentElement.setAttribute( "readonly", true );
      currentElement.classList.add( "tableinput" );
    },
    navigateThroughInputs( currentElement, direction ) {
      const inputs = document.getElementById('detailstable').querySelectorAll('input,a')

      const currentIndex = Array.from( inputs ).indexOf( currentElement );

      let nextIndex = 0;
      if( currentIndex < inputs.length - 1 ) {  
        nextIndex = direction ? currentIndex + 1 : currentIndex - 1;
      } else {
        nextIndex = 0;
      }

      inputs.item( nextIndex ).focus();
    },
    formatValuesOnBlur( index, property ) {
      const currentElement = { ...this.AcountList[ index ] };

      if( currentElement[ property ] === '' ) {
        currentElement[ property ] = 0;
      }
      currentElement[ property ] = parseFloat( currentElement[ property ] ).toFixed(2);

      if( currentElement[ property ] > 0 ) {
        currentElement.isDefault = false;
      }
      this.AcountList[ index ] = { ...currentElement };

      if( !this.AcountList[ index + 1 ]?.account_code && !this.AcountList[ index + 1 ]?.isDefault ) {
        this.AcountList.push( { ...this.defaultAccountItem } )
      }

      this.sums();
    },
    throughEvents( events, index ) {
      if( events ) {
        const arrayOfEventNames = Object.keys(events);
  
        const eventsObj = {}
  
        for( const eventName of arrayOfEventNames ) {
  
          eventsObj[ eventName ] = e => {
            events[ eventName ](index, e)
          };
        }
  
        return eventsObj
      }

      return {};
    },
    closeAccountsCatalog() {
      this.openCatalogByEnter = false;
      this.selectedToAssign = null;
    },
    deletebycell(e){
      // console.log('eliminando');
      // console.log(e.key);
      if(e.key == 'Enter'){
        // console.log(e.key, this.selectedToDelete);
        if(this.selectedToDelete) {
          this.confirmDeleteAccountingEntryDetail(this.selectedToDelete);
        }
      }
    },
    // validateEnterByCell(e) {
    //   try {
    //     if(e.key == 'Enter'){
    //       // if( this.deleteByEnter && !this.selectedToDelete.data.isDefault ) {
    //       //   this.confirmDeleteAccountingEntryDetail(this.selectedToDelete);
    //       // }
  
    //       if( this.openCatalogByEnter ) {
    //         this.chooseAccount( this.selectedToAssign );
    //       }
    //       // console.log(e.key, this.selectedToDelete);
    //       // if(this.selectedToDelete) {
    //       //   this.confirmDeleteAccountingEntryDetail(this.selectedToDelete);
    //       // }
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    //   // console.log('eliminando');
    // },
    deleteeed(e){
      // console.log(e.columnIndex);
      if( e.columnIndex == 7 ){
        // console.log('aca');
        this.openCatalogByEnter = false;
        this.selectedToAssign = null;
        this.deleteByEnter = true;
        this.selectedToDelete = e.row;
      } else 
      if( e.columnIndex == 0 || e.columnIndex == 1 ) {
        // console.log('aquí');
        this.deleteByEnter = false;
        this.selectedToDelete = null;
        this.openCatalogByEnter = true;
        this.selectedToAssign = e.row;
      }
      // else {
      //   if( e.columnIndex != 7 ) {
      //   } else if( e.columnIndex != 0 || e.columnIndex != 1 ){
      //   }
      //   // this.preventEnterCounter = 0;
      //   // this.preventEnterCounter++;
      //   // if(this.preventEnterCounter > 1){
      //   // }
      // }
    },
    checkTab(event) {
 
      if (event.key === 'Tab') {
          event.preventDefault(); // Previene el comportamiento por defecto de la tecla Tab
          
          // this.$refs.detailsGrid.instance.focus();
      }
    },
 
    validateData(e) {
      // console.log(e.newData.account_code);
      // console.log(this.AcountListN);
      if(e.newData.account_code){
        const found = this.AcountListN.find( current => current.account_code === e.newData.account_code && current.SelectedItem );

        // console.log(found);
        if(found){
          // e.newData.newId_account = found.id_account;
          e.newData = {
            ...e.newData,
            ...found,
            Hab: 0.0,
            Deb: 0.0,
            isDefault: false
          };
          // const exist = this.AcountList.find(
          //   (item) => item.account_code == e.newData.account_code
          // );
          // if (exist) {
          //   e.cancel = true;
          //   this.showAlert("warning", "Error", "Ya existe una cuenta con el mismo código","" , "", false );
          //   return;
          // } else {
            this.AcountList.push( { ...this.defaultAccountItem } );
          // }
        }
        // else{
        //   e.cancel = true;
        //   this.showAlert("warning", "Error", "La cuenta ingresada no existe o no es candidata para crear un movimiento","" , "", false );
         
        // }
      }
    },
    accountCodeTyped(index){
      const { account_code, id_account } = this.AcountList[ index ];
      const found = this.AcountListN.find( current => current.account_code === account_code && current.SelectedItem );

      if(id_account != found.id_account ) {
        if(account_code){
  
          
          if(found){
            const { id_accounting_entry_detail } = this.AcountList[ index ];

            this.AcountList.splice( index, 1 );

            this.AcountList[index] = {
              id_accounting_entry_detail,
              ...found,
              Hab: "0.00",
              Deb: "0.00",
              isDefault: false
            };

            this.AcountList.push( { ...this.defaultAccountItem } );
          }
          // else{
          //   e.cancel = true;
          //   this.showAlert("warning", "Error", "La cuenta ingresada no existe o no es candidata para crear un movimiento","" , "", false );
           
          // }
        }
      }
    },
    addAccount(){
      this.showModel = true;
      this.keyModel++;
      this.targetIndex = null;
    },
    chooseAccount(index) {
      // const { rowIndex } = data;
      this.showModel =true
      this.keyHistory++;
     
      //this.$refs.accountinghistory.createListener();
      this.targetIndex = index;
      // console.log(data);
    },
    getAcounteringType() {
      this.$API.branchaccountcatalog
        .getAcounteringType()
        .then((res) => {
          this.TypesItems = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAccountingDetailTypes() {
      this.$API.accountingentrydetailtype
        .getAccountingEntryDetailTypes()
        .then((res) => {
          this.detailTypes = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    canChangue(rowData) {
      if (rowData.type == "Debe") {
        return true;
      }
      return false;
    },
    confirmDeleteAccountingEntry() {
      this.showAlert(
        "question",
        "¿Está seguro de eliminar el la partida contable?",
        "Esta acción no se puede deshacer",
        "deleteAccountingEntry"
      );
    },
    confirmDeleteAccountingEntryDetail(index) {
      this.showAlert(
        "question",
        "¿Está seguro de eliminar el detalle?",
        "Esta acción no se puede deshacer",
        "deleteDetail",
        index
      );
    },
    confirmCleanAccountingEntryForm() {
      this.showAlert(
        "question2",
        "¿Está seguro de limpiar el formulario actual?",
        "Perderá los cambios no guardados",
        "clean"
      );
    },
    reaciveSelection(data) {
      // const { account_code } = data;
      this.showModel = false;
      // const exist = this.AcountList.find(
      //   (item) => item.account_code == account_code
      // );
      // if (exist) {
      //   this.showAlert("danger", "Error", "Ya existe una cuenta con el mismo código");
      //   return;
      // }
      if(typeof this.targetIndex != 'number') {
        this.AcountList.pop();
        this.AcountList.push( { ...data, ...this.defaultAccountItem, isDefault: false } );
        this.AcountList.push( { ...this.defaultAccountItem } );
      } else {
        this.AcountList.pop();
        this.AcountList[ this.targetIndex ] = {
          ...data,
          ...this.defaultAccountItem,
          isDefault: false
        };
        this.AcountList.push( { ...this.defaultAccountItem } );
        this.targetIndex = null;

        if( this.openCatalogByEnter ) {
          this.openCatalogByEnter = false;
          this.selectedToAssign = null;
        }
      }
    },
    sums() {
      this.Totals.TotalDebe = this.SumDeb();
      this.Totals.TotalHaber = this.SumHab();
      this.Totals.Total = this.TotalAcount();
    },
    SumDeb() {
      let x = 0;
      if (this.AcountList.length == 0) {
        return 0;
      }

      this.AcountList.forEach((element) => {
        x += +element.Deb;
      });
      if (typeof x == "string" || isNaN(x)) {
        x = 0;
      }
      return x.toFixed(2);
    },
    SumHab() {
      let x = 0;
      if (this.AcountList.length == 0) {
        return 0;
      }

      if (this.AcountList[0].Hab == null) return 0;
      this.AcountList.forEach((element) => {
        x += +element.Hab;
      });
      if (typeof x == "string" || isNaN(x)) {
        x = 0;
      }
      return x.toFixed(2);
    },
    TotalAcount() {
      return (this.SumDeb() - this.SumHab()).toFixed(2);
    },
    getTotals(e) {
      try {
        this.logEvent(e);
        if(e.cancel)
        
        // e.data.Hab = e.data.Hab.toFixed(2)
        // e.data.Deb = e.data.Deb.toFixed(2)
        this.Totals.TotalDebe = this.SumDeb();
        this.Totals.TotalHaber = this.SumHab();
        this.Totals.Total = this.TotalAcount();
        
      } catch (error) {
        console.log(error);
      }
    },
    changueD(date) {
      this.date = date;
    },
    async getAllAcounts() {
      try {
        const result = await this.$API.branchaccountcatalog.getActiveBranchAccountCatalog();
        this.AcountListN = result.data;
      } catch (error) {
        console.log(error);
      }
    },
    onEditingStart(e) {
      if (!this.editableFieldsList.includes(e.column.dataField)) {
        e.cancel = true;
      }
    },
    logEvent(e) {
      e.data.Hab = parseFloat(e.data.Hab).toFixed(2)
      e.data.Deb = parseFloat(e.data.Deb).toFixed(2);
      // if ( e.data.Hab != 0.00 && e.data.Deb != 0.00)
      // {
      //   this.showAlert("danger", "Error", "No puede tener un valor en debe y haber");
      //   e.cancel = true;
      //   return;
      // }
    },
    onExporting(e) {
      printToExcel(e, "Servicios");
    },

    formatAccounts() {
      for (const index in this.AcountList) {
        this.AcountList[index].id = this.AcountList[index].id_account;
      }
    },
    closeAlert() {
      this.alert.show = false;
    },
    async acceptAlert() {
      this.alert.show = false;

      const { data, options } = this.alert;

      !data ? await this[ options ]() : await this[ options ]( data );

      // if (this.alert.options == "deleteaccountingentry") {
      //   this.deleteAccountingEntry();
      //   //(this.alert.data);
      // }
    },
    showAlert(type, header, body, options = null, data = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
      this.alert.data = data != null ? data : null;
    },
    deleteDetail(index) {
      // console.log(index);
      if (this.editing) {
        this.RemovedAccountList.push(this.AcountList[index]);
      }
      this.AcountList.splice(index, 1);

      // console.log(this.AcountList)

      if(this.AcountList.length === 0) {
        this.AcountList.push( { ...this.defaultAccountItem } );
      }

      // console.log(this.AcountList)
      if( this.selectedToDelete ) {
        this.selectedToDelete = null;
        this.deleteByEnter = false;
      }
    },

    closeDialog() {
      this.serviceToDelete = null;
      this.editService = false;
      this.showModel = false;
      this.deleteDialog = false;
    },
    async setupvalues(data) {
      this.clean();
      
      this.loading = true;

      try {
        this.showHistory = false;
        // console.log(data);
        // console.log( data.accounting_entry_date)
        this.editing = true;

        this.$emit( 'updatingAccountingEntry', data.ID_Accounting_item );

        const { data: { accountingEntry, accountingEntryDetails } } = await this.$API.accountingentryheader.getOneAcountingHeader( data.id_accounting_entry );


        this.form.ID_Accounting_item = accountingEntry.ID_Accounting_item;
        this.form.id_accounting_entry = accountingEntry.id_accounting_entry;
        this.date = moment( new Date( accountingEntry.accounting_entry_date ) ).format( 'DD/MM/YYYY' );
        this.form.concept = accountingEntry.accounting_entry_comment;
        this.form.type = accountingEntry.id_accounting_entry_type;
        const details = [];
        
        accountingEntryDetails.map((item) => {
          // console.log(item);
          const index = this.AcountListN.find(
            (current) => current.id_account == item.id_account
          );
  
          item.account_code = index.account_code;
          item.account_description = index.account_description;
  
          if (item.id_entry_detail_type == 2) {
            item.Deb = item.amount;
            item.Hab = 0;
          } else if (item.id_entry_detail_type == 1) {
            item.Hab = item.amount;
            item.Deb = 0;
          }
  
          details.push(item);
        });
        // console.log(details, 'detalle');
        this.AcountList = [ ...details ];
        this.formatAccounts();
      } catch (error) {
        console.log( error );
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async deleteAccountingEntry() {
      const { id_accounting_entry } = this.form;
      try {
        this.loading = true;
        const {
          message,
          id_accounting_entry: responded_id_accounting_entry,
        } = await this.$API.accountingentryheader.deleteAccountingEntryHeader({
          accountingentry: { id_accounting_entry },
        });

        this.showAlert(
          "successWithCallback",
          "Registro Eliminado",
          `${message} ID: ${responded_id_accounting_entry}`,
          "clean",
        );
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.showAlert(
          "danger",
          "Error",
          `Ocurrió el siguiente error al intentar eliminar los datos de la partida ${id_accounting_entry} ${error.message}`
        );

      }
    },
    async saveAccountingEntry() {
      try {
        if (!this.valid) {
          this.showAlert("danger", "Error", "Debe llenar todos los campos");
          return;
        }
        this.loading = true;
        this.AcountList.pop();

        // console.log('this.AcountList ', this.AcountList);
        // this.$refs.detailsGrid.instance.saveEditData();

        // console.log("guardando");
        const hasTransaction = this.AcountList.filter(
          (current) => current.Deb === 0 && current.Hab === 0
        );
        if (hasTransaction.length > 0) {
          this.showAlert(
            "danger",
            "Error",
            "La partida no debe contener registros que no posean Debe o Haber"
          );
          this.AcountList.push( { ...this.defaultAccountItem } );
          this.loading = false;
          return;
        }

        let someInvalidAccount = '';
        
        for( const element of this.AcountList ) {
          const { account_code } = element;
          const found = this.AcountListN.find( current => current.account_code === account_code && current.SelectedItem );
          if(!found){
            someInvalidAccount = account_code
          }
        }

        if(someInvalidAccount.length > 0) {
          this.showAlert("warning", "Error", `La cuenta ${someInvalidAccount} no existe o no es candidata para crear un movimiento`,"" , "", false );
          this.loading = false;
          return;
        }

        const invalidTransaction = this.AcountList.filter(
          current => current.Deb > 0 && current.Hab > 0
        )

        if(invalidTransaction.length > 0) {
          this.showAlert(
            "danger",
            "Error",
            "Los detalles de la partida deben de tener un monto asignado en Debe o en Haber, no ambos"
          );
          this.AcountList.push( { ...this.defaultAccountItem } );
          this.loading = false;
          return;
        }

        for (const index in this.AcountList) {
          if (this.AcountList[index].Deb > 0) {
            this.AcountList[index].id_entry_detail_type = this.detailTypes.find(
              (current) => current.entry_detail_type_description === "DEBER"
            ).id_entry_detail_type;
            this.AcountList[index].amount = this.AcountList[index].Deb;
          } else if (this.AcountList[index].Hab > 0) {
            this.AcountList[index].id_entry_detail_type = this.detailTypes.find(
              (current) => current.entry_detail_type_description === "HABER"
            ).id_entry_detail_type;
            this.AcountList[index].amount = this.AcountList[index].Hab;
          }
        }

        const { type, concept } = this.form;

        // console.log(this.date);

        if (!this.editing) {
          const payload = {
            accountingentry: {
              id_accounting_entry_type: type,
              accounting_entry_date: new Date( formatDateV2( this.date ) ),
              accounting_entry_comment: concept,
              accounting_entry_value: this.AcountList.reduce(
                ( accumulator, currentValue ) => {
                  const { Deb } = currentValue;
                  const valueToAccum = parseFloat(Deb);
                  // console.log('Deb ', typeof Deb);
                  // console.log('accumulator ', accumulator);
                  if (valueToAccum > 0) {
                    return accumulator + valueToAccum;
                  } else {
                    return accumulator;
                  }
                },
                0.0
              ),
              accountingentrydetails: this.AcountList.map((current) => {
                const { id_account, id_entry_detail_type, amount, concept } = current;
                return {
                  id_account,
                  id_entry_detail_type,
                  amount,
                  concept
                };
              }),
            },
          };
          await this.$API.accountingentryheader.createAccountingEntryHeader(
            payload
          );
        } else {
          const { id_accounting_entry } = this.form;

          const entryDetails = [
            ...this.AcountList.map((current) => {
              const {
                id_accounting_entry_detail,
                id_account,
                id_entry_detail_type,
                amount,
                concept: detailConcept,
              } = current;
              return {
                id_accounting_entry_detail,
                id_account,
                id_entry_detail_type,
                concept: detailConcept,
                amount,
              };
            }),
            ...this.RemovedAccountList.map((current) => {
              const { id_accounting_entry_detail } = current;
              return {
                id_accounting_entry_detail,
                removed: true,
              };
            }),
          ];

          const payload = {
            accountingentry: {
              id_accounting_entry,
              id_accounting_entry_type: type,
              accounting_entry_date: new Date(formatDateV2(this.date)),
              accounting_entry_comment: concept,
              accounting_entry_value: this.AcountList.reduce(
                (accumulator, currentValue) => {
                  const { Deb } = currentValue;
                  if (Deb > 0) {
                    return accumulator + parseFloat( Deb );
                  } else {
                    return accumulator;
                  }
                },
                0.0
              ),
              accountingentrydetails: [...entryDetails],
            },
          };

          await this.$API.accountingentryheader.updateAccountingEntryHeader(
            payload
          );
        }
        this.showAlert(
          "successWithCallback",
          "Registro guardado",
          `Se guardaron los datos de la partida contable correctamente`,
          "clean"
        );
        this.loading = false;
      } catch (error) {
        this.AcountList.push( { ...this.defaultAccountItem } );
        this.loading = false;
        this.showAlert(
          "danger",
          "Error",
          `Ocurrió el siguiente error al intentar guardar los datos ${error.message}`
        );
      }
    },
    enableEditing() {
      this.allowEditing = true;
      this.AcountList.push( { ...this.defaultAccountItem } );
    },
    clean(example = true) {
      this.form = {
        concept: "",
        type: null,
      };
      this.$refs.accountingEntryForm.reset();
      this.editing = false;
      this.allowEditing = false;
      if (example) 
       this.AcountList = [ { ...this.defaultAccountItem } ];
      this.RemovedAccountList = [];
      this.date = this.moment().format("DD/MM/YYYY");
      this.alert = { ...this.defaultAlert };
    },
  },
};
</script>

<style scoped>
.close-icon {
  color: red;
}

.btnsave {
  background-color: #00a178 !important;
  color: white;
}

.btndisable {
  background-color: #41464c !important;
  color: white;
}
.text-black {
  color: black;
}
.centered {
  display: flex;
  justify-content: center;
}

.bar {
  width: 100%;
  background-color: rgb(60, 60, 60) !important;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-radius: 15px !important;
  margin-top: 1rem !important;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.tableinput:focus {
  border: 1px solid black;
}

.tableinputeditable:focus {
  border: 1px solid rgb(17, 0, 255);
}
/* tr:nth-child(even) {
  background-color: #dddddd;
} */
</style>
